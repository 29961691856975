'use client'

import classNames from "classnames";
import { usePathname, useRouter } from "next/navigation"
import React, { useEffect, useRef, useState } from "react"

interface heading {
    text: string
    id: string
    level: number
}

export const ToC = (props: React.HTMLProps<HTMLElement>) =>  {
    const [headings, setHeadings] = useState([] as heading[])
    const observer = useRef<IntersectionObserver>()
    const [activeId, setActiveId] = useState('')
    const pathname = usePathname()
    const router = useRouter()

    useEffect(() => {
        const handleObsever = (entries:IntersectionObserverEntry[]) => {
            const actives = entries.filter((e) => e?.isIntersecting);

            if (actives.length) {
                setActiveId(actives[0].target.id)
            }
        }

        observer.current = new IntersectionObserver(handleObsever, {
            rootMargin: "0% 0% -95% 0%",
        })

        const elements = document.querySelectorAll(".ck h2, .ck h3")
        elements.forEach((elem) => observer.current?.observe(elem))

        return () => observer.current?.disconnect()
    }, [pathname])


    useEffect(() => {
        const elements = Array.from(document.querySelectorAll(".ck h2, .ck h3"))
            .map((elem) => ({
                id: (elem as HTMLElement).id,
                text: (elem as HTMLElement).innerText.replace('¶', ''),
                level: Number(elem.nodeName.charAt(1))
            }))
        setHeadings(elements)

        return () => setHeadings([])
    }, [pathname])

    if (headings.length == 0) {
        return <></>
    }

    return (
        <nav {...props}>
          <h4 className="pl-2">On this page</h4>
          <ul className="border-l border-navy-600 text-sm">
            {headings.map(heading => (
                <li key={heading.id} className={classNames(
                    getClassName(heading.level),
                    (activeId === heading.id) ? "border-navy-600" : "border-navy-600/0 hover:border-navy-400 hover:bg-gray-500", 
                    "border-l-2 transition-colors duration-300"
                    )}>
                    <a 
                    href={`#${heading.id}`}
                    className={classNames((activeId === heading.id) ? "font-semibold" : "", "block py-1")}
                    onClick={(e) => {
                        e.preventDefault()
                        const selector = `#${heading.id}`
                        document.querySelector(selector)?.scrollIntoView({
                            behavior: "smooth"
                        })
                        router.push(selector, {scroll: false})
                    }}>{heading.text}</a>
                </li>
            ))}
          </ul>
        </nav>
    );
}

const getClassName = (level:number) => {
    switch (level) {
      case 2:
        return 'pl-2'
      case 3:
        return 'pl-6'
      case 4:
        return 'head4'
      default:
        return ''
    }
  }