'use client'

import { useUserInformation } from "@/contexts/UserProvider"
import { EyeIcon, PencilSquareIcon } from "@heroicons/react/24/outline"
import { DrupalNode } from "next-drupal"

export const AcquianFeedback = ({ resource }: {
  resource?: DrupalNode
}) => {
  const { isAcquian } = useUserInformation()

  if (!isAcquian) {
    return <></>
  }

  return <div className="container mx-auto my-4 rounded-lg p-5 pb-2 bg-gray-400">
  <div className="flex gap-1 text-blue-600 text-sm mb-2"><EyeIcon className="w-4" /> Acquian only</div>
  <h3 className="text-blue-600 text-xl font-medium">{"Can't find what you're looking for?"}</h3>
  <p className="mb-2">If you think you&apos;ve found a gap in our content, let us know and make a request to our content team.</p>
  <p className="my-2 py-4 transition-colors duration-300 flex gap-2 items-center">
    <a 
      title="This will take you to Jira to create the request." 
      className="p-4 flex whitespace-nowrap hover:bg-blue-800 text-white bg-blue-600 rounded after:bg-white" 
      href="https://acquia.atlassian.net/jira/software/c/projects/DOC/forms/form/direct/1/3" 
      target="_blank"
    >Make a content request</a>
    { resource && (
      <> or 
      <a href={`/api/edit?nid=${resource.drupal_internal__nid}`} title="Edit content in the Drupal CMS" className="flex gap-1 p-4 hover:bg-orange-600 hover:text-white text-orange-600 border border-orange-600 bg-white rounded after:bg-white">Edit this page <PencilSquareIcon className="w-4" /></a>
      </>
      
    )}
  </p>
</div>
}