'use client'

import { DrupalMenuLinkContent, DrupalNode } from 'next-drupal';
import React, { ReactNode, useEffect, useState } from 'react';
import { Menu } from '../../block/Menu';
import { useSelector } from 'react-redux';
import { AppState } from '@/redux/reducer';
import { useVersion } from '@/lib/drupal/util';
import { getSidebarMenu } from '@/lib/drupal/get-menus.server';


export const SidebarVersionedMenu = (props: {
  resource: DrupalNode
  product: string
  defaultMenu: DrupalMenuLinkContent[]
  children?: ReactNode
  versions?: string[]
}) => {
  // const menus = useRef<DrupalMenuLinkContent[]>(props.defaultMenu)
  const [menus, setMenu] = useState(props.defaultMenu)

  const versionChoice = useVersion(
      useSelector<AppState, string>((state: AppState) => {
        return state.versionPreferences[props.product]
      }),
      props.versions ?? []
  )

  useEffect(() => {
    if (versionChoice !== null) {
      getSidebarMenu(props.resource, versionChoice).then((menu: DrupalMenuLinkContent[]) => {
        // menus.set = menu
        setMenu(menu)
      })
    }
  }, [versionChoice, props.resource])

  return (
    <div className="pr-12">
      {props.children}
      <Menu menu={menus} className="flex flex-col text-navy-600" id="sidebar-menu" />
    </div>
  )
}