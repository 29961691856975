'use client'

import { ReactNode, useEffect } from "react"
import { ToC } from "@/components/wysiwyg/ToC.client"
import { Body } from "@/components/wysiwyg/Body"
import { DrupalNodeArticle, DrupalNodeBasicPage, DrupalTaxonomyTermProduct } from "@/types"
import HtmlDiff from 'htmldiff-js';
import { DrupalTranslatedPath } from "next-drupal"
import { permanentRedirect } from "next/navigation"
import { StatusMessage } from "../../wysiwyg/StatusMessage"
import { useSelector } from "react-redux"
import { AppState } from "@/redux/reducer"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import Link from "next/link"
import { PreviewBarContextProps, usePreviewBar } from "@/contexts/PreviewBarProvider"
import { PreviewTag } from "./PreviewBar.client"

export const PageContent = ({resource, revision, inDraft, children = undefined, redirect = undefined, versions, versionChoice, footer}: {
  resource: DrupalNodeBasicPage | DrupalNodeArticle
  revision: DrupalNodeBasicPage | DrupalNodeArticle | null
  versions?: string[]
  versionChoice?: string
  inDraft: boolean
  children?: ReactNode
  footer?: ReactNode
  redirect: DrupalTranslatedPath["redirect"]
}) => {

  useEffect(() => {
    const hash = window.location.hash;
    // Check if redirect is presnt and append the section hash at the end of redirect.
    if (redirect !== undefined) {
      permanentRedirect(redirect[0].to+hash)
    }
  }, [redirect])
  
  const params = useSearchParams()
  const router = useRouter()
  const pathname = usePathname()
  const { preview: { mode }} = usePreviewBar()
  const node = revision ?? resource
  const product:DrupalTaxonomyTermProduct = resource.field_product ?? resource.field_products[0]
  const userPreferredVersion = useSelector<AppState, string>((state: AppState) => state.versionPreferences[product.name]);

  useEffect(() => {
    // When there are versions available and no version has been specified in the "v"
    // query parameter, then we'll set the v parameter with either the user preferred
    // version or the latest version.
    if (versions && versions.length > 0 && !params.has('v')) {
      router.replace(pathname + '?v=' + (userPreferredVersion ?? versionChoice), {
        scroll: false,
      })
    }
  }, [versions, params, pathname, router, versionChoice, userPreferredVersion])

  const versionLatest = product.field_current_version?.name

  const previewResource = mode == 'revision' ? node : resource
  const bodyContent = !inDraft ? node.body?.processed :
    (mode == 'diff' && HtmlDiff.execute(resource.body?.processed, revision?.body?.processed ?? ''))
    ||
    previewResource.body?.processed

  const modes:PreviewBarContextProps['preview']['modes'] = ['current']
  if (revision && revision.drupal_internal__vid != resource.drupal_internal__vid) {
    modes.push('revision', 'diff')
  }

  // Boolean on whether to use the ToC or not.
  const useToC = bodyContent?.match('id="contents"') !== null;
  const main_span = useToC ? 'col-span-2' : 'col-span-3'
  const newBodyContent = bodyContent?.match('<body>') !== null ? bodyContent?.replaceAll('<body>','').replaceAll('</body>','') : bodyContent;

  return (
    <>
      <PreviewTag mode={mode ?? 'current'} modes={modes} resource={previewResource} state={previewResource.moderation_state as 'draft' | 'published'} />
      {(useToC) && (
        <div className="order-last">          
          {useToC && <ToC className="order-last col-span-1 sticky top-4 self-start hover:overscroll-y-contain overflow-y-auto lg:h-screen toc" />}
        </div>
      )}
      <div className={`meta-content ${main_span} mode-${mode}`}>
          {children}
          { versionLatest !== null && versionChoice !== versionLatest && (
            <StatusMessage type="important">
              <p>{`You're viewing version ${versionChoice} of this page.`}&nbsp;
                <Link href={pathname + '?v=' + versionLatest} className="text-blue-600 underline">View latest ({versionLatest}) documentation</Link>.
              </p> 
            </StatusMessage>
          )}
          <Body value={newBodyContent || ''} sectionScroll={true} className="max-w-4xl" version={versionChoice} />
          {footer}
      </div>
    </>
  )
}