import { DrupalMetaTag, DrupalNodeBasicPage } from "@/types"

/**
 * Logic for default version selection.
 */
export function useVersion(preference: string | undefined, versions: string[]) {
  return preference ?? (versions.length ? versions[0] : null)
}

interface Metadata {
  title?: string
  description?: string
}

export function getMetaTags(node: DrupalNodeBasicPage): Metadata {
  const metatags = node.metatag?.filter(t => t.tag == "meta") as DrupalMetaTag[]
  const product = node.field_product.name
  return {
    title: [metatags.filter(t => t.attributes.name == 'title').pop()?.attributes.content, product].join(' | '),
    description: metatags.filter(t => t.attributes.name == 'description').pop()?.attributes.content
  }
}