'use client'
import Link from 'next/link';
import { DrupalMenuLinkContent } from 'next-drupal';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { VersionedUrlManager, makeDrupalMenuLinkContent } from '@/lib/utils';
import { DrupalTaxonomyTermProduct } from '@/types';
import { usePathname } from 'next/navigation';
import classNames from 'classnames';

interface BreadcrumbProps {
    menu: DrupalMenuLinkContent[] | null
    product: DrupalTaxonomyTermProduct
    relatedProducts?: DrupalTaxonomyTermProduct[]
    className?: string
    version?: string
}

interface StaticText {
    title: string
    id: string
    url?: string
    route?: {
        name: string
    }
}

function hasActiveItem(item: DrupalMenuLinkContent, path: string, versionedUrlManager: VersionedUrlManager): false | DrupalMenuLinkContent[] {

    if (path.indexOf('#') !== -1) {
        path = path.substring(0, path.indexOf('#'))
    }
    if (path.indexOf('?') !== -1) {
        path = path.substring(0, path.indexOf('?'))
    }

    if (path === versionedUrlManager.translatePath(item.url)) {
        return [item];
    }
    if (!item.items?.length) {
        return false;
    }
    const activeItems = item.items.filter((i) => hasActiveItem(i, path, versionedUrlManager))
    const items = activeItems.map((i) => hasActiveItem(i, path, versionedUrlManager)).pop();

    if (items == false || items == undefined) {
        return false;
    }

    const breadcrumb = [item];
    breadcrumb.push(...items);
    return breadcrumb;
}

export function Breadcrumb({ menu, product, version, ...props }: BreadcrumbProps) {
    const path = usePathname()
    const versionedUrlManager = new VersionedUrlManager(version)
    versionedUrlManager.setDefaultPosition(versionedUrlManager.getVersionPosition(path))
    const relatedProducts:DrupalTaxonomyTermProduct[]|null = product.parent[0].id == "virtual" ? null : product.parent
    const HomeLink: DrupalMenuLinkContent = makeDrupalMenuLinkContent({
        title: 'Home',
        id: 'home',
        description: '',
        route: {
            name: '<front>',
            parameters: {}
        },
        url: '/',
        items: product ? [makeDrupalMenuLinkContent({
            title: product.name,
            url: product.field_landing_page.uri?.replace('internal:', ''),
            route: {
                name: 'product'
            },
            items: menu
        })] : []
    });

    let breadcrumb: (StaticText | DrupalMenuLinkContent)[] = hasActiveItem(HomeLink, path, versionedUrlManager) || [HomeLink];

    // Splice in the Add-ons breadcrumb path if applicable
    if (relatedProducts != null) {

        // Pop-off the home link.
        let newCrumb: (StaticText | DrupalMenuLinkContent)[] = [];

        let crumb;
        if (crumb = breadcrumb.shift()) {
            newCrumb.push(crumb);
        }

        newCrumb.push(makeDrupalMenuLinkContent({
            title: relatedProducts[0].name,
            url: relatedProducts[0].field_landing_page.uri?.replace('internal:', ''),
        }))
        newCrumb.push({
            title: 'Add Ons',
            id: 'add_ons',
            route: { name: '' }
        })
        newCrumb.push(...breadcrumb);
        breadcrumb = newCrumb;
    }

    // Basecrumb is the first 2 elements.
    const baseCrumb = [breadcrumb.shift(), breadcrumb.shift()].filter(v => v !== undefined) as (DrupalMenuLinkContent | StaticText)[];

    // Active page is the last crumb.
    const lastCrumb = (breadcrumb.length ? breadcrumb.pop() : baseCrumb.pop()) as DrupalMenuLinkContent | StaticText;

    // Add-ons are the third and fourth crumb when the 3rd is StaticText.
    let staticCrumb = [] as (DrupalMenuLinkContent | StaticText)[];

    if (breadcrumb.length && (breadcrumb[0].url === undefined)) {
        staticCrumb.push(breadcrumb.shift() as StaticText)

        if (breadcrumb.length) {
            staticCrumb.push(breadcrumb.shift() as DrupalMenuLinkContent)
        }
    }

    // What is left are collapsible crumbs.
    const collapsibleCrumbs = breadcrumb;

    return (
        <ul {...props} className={classNames('gap-1 mb-4', props.className)}>
            {baseCrumb.map((c: DrupalMenuLinkContent | StaticText, i: number) => {
                return (
                    <li key={c.id} className={`flex whitespace-nowrap`}>
                        {c.url ? (
                            <Link href={c.url} className='text-blue-600 text-ellipsis hover:bg-gray-400 rounded px-1'>{c.title}</Link>
                        ) : (c.title)}
                        <ChevronRightIcon className='ml-2 w-6 overflow-visible text-gray-600' />
                    </li>
                )
            })}

            {(staticCrumb.length) > 0 && (
                <>
                {staticCrumb.map((c: DrupalMenuLinkContent | StaticText, i: number) => {
                    return (
                        <li key={`breadcrumb-${c.id}`} className={`flex whitespace-nowrap`}>
                            {c.url ? (
                                    <Link href={c.url} title={c.title} className='text-blue-600 hover:bg-gray-400 rounded px-1'>{c.title}</Link>
                                )
                                : (c.title)}
                            <ChevronRightIcon className='ml-2 w-6 overflow-visible text-gray-600' />
                        </li>)
                })}
                </>
            )}

            {breadcrumb.length > 0 && (
                <li className='flex-shrink flex truncate'>
                    <ul className='flex gap-1 truncate'>
                        {breadcrumb.map((c: DrupalMenuLinkContent | StaticText, i: number) => {
                            const truncate = c.url ? '[&:not(:hover)]:truncate' : ''
                            'basis-1/2 basis-1/3 basis-1/4 basis-1/5 basis-1/6'
                            const basis = 'basis-1/' + breadcrumb.length;
                            return (
                                <li key={c.id} className={`flex whitespace-nowrap ${truncate}`}>
                                    {c.url ? (
                                        <Link href={versionedUrlManager.translatePath(c.url)} title={c.title} className='text-blue-600 truncate hover:bg-gray-400 rounded px-1'>{c.title}</Link>
                                    )
                                        : (c.title)}
                                    { i < (breadcrumb.length - 1) && (<ChevronRightIcon className='ml-2 w-6 overflow-visible text-gray-600' />)}
                                </li>
                            )
                        })}
                    </ul>
                    <ChevronRightIcon className='ml-2 w-6 overflow-visible text-gray-600' />
                </li>
            )}
            
            <li key={lastCrumb.id} className={`flex whitespace-nowrap`}>{lastCrumb.title}</li>
        </ul>
    );
}